import React, { useEffect, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import Dialog from '../Dialog/Dialog';
import GradeDescription from './GradeDescription';
import GradeMixChartPreview from './GradeMixChartPreview';
import { PrimaryIconButton, TextField } from '../AtomComponents';

import { genericGradesListType } from '../../types/StockListTypes';

import './GradePopup.scss';
import { I18nContext } from '../../I18n';
import { isEmpty } from '../../_lib/util';

interface GradePopupProps {
  grade: string | undefined;
  isDialogOpen: boolean;
  closeDialog: any;
  pxnParticipantId: any;
  gradesList: genericGradesListType[];
  stockListItems: any;
}

function findGradesAvailableToTheBuyer(stockListItems: any) {
  const grades = new Set();
  if (!isEmpty(stockListItems['groups'])) {
    Object.keys(stockListItems['groups']).forEach((group: any) => {
      stockListItems['groups'][group]['items']?.forEach((item: any) => {
        grades.add(item['grade']);
      });
    });
  }
  return grades;
}

const GradePopup = (props: GradePopupProps) => {
  const [selected, setSelected] = useState<{ sectionIdx: number; gradeIdx: number }>();
  const [sections, setSections] = useState<string[]>([]);
  const [grades, setGrades] = useState<genericGradesListType[]>([]);
  const [filteredGradesList, setFilteredGradesList] = useState<genericGradesListType[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const I18n = React.useContext(I18nContext);

  useEffect(() => {
    if (!props.grade || !filteredGradesList) return;

    const grade = props.gradesList.find((grade) => grade.grade === props.grade);
    if (!grade) return;

    const filteredGrades = filteredGradesList.filter(
      (gradeObj) => gradeObj.gradeSection === grade.gradeSection
    );
    const sectionIdx = sections.findIndex((section) => section === grade.gradeSection);
    const gradeIdx = filteredGrades.findIndex((gradeObj) => gradeObj.grade === grade.grade);
    setGrades(filteredGrades);
    setSelected({
      gradeIdx: gradeIdx === -1 ? 0 : gradeIdx,
      sectionIdx,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.grade, filteredGradesList]);

  useEffect(() => {
    if (!props.stockListItems || !props.gradesList) return;
    //Filter unique grades from the stocklist items to make sure only grades that are available to the buyer are shown on the popup.
    //The popup should only show grades relevant to the selected warehouse group and the user's warehouse access.
    const gradesAvailableToTheBuyer = findGradesAvailableToTheBuyer(props.stockListItems);

    const filteredGradesList = props.gradesList.filter((grade) =>
      gradesAvailableToTheBuyer.has(grade.grade)
    );

    const sections = Array.from(
      new Set(
        filteredGradesList.map((grade) => grade.gradeSection || '').filter((section) => !!section)
      )
    );
    const filteredGrades = sections.length
      ? filteredGradesList.filter((grade) => grade.gradeSection === sections[0])
      : filteredGradesList;

    setFilteredGradesList(filteredGradesList);
    setGrades(filteredGrades);
    setSections(sections);
    setSelected({
      sectionIdx: 0,
      gradeIdx: 0,
    });
  }, [props.stockListItems, props.gradesList]);

  const onSelectChange = (type: 'grade' | 'section') => (event: any) => {
    if (!filteredGradesList || !selected) return;
    const updatedSelected = { ...selected };
    if (type === 'section') {
      updatedSelected.sectionIdx = +event.target.value;
      const section = sections[updatedSelected.sectionIdx];
      const filteredGrades = filteredGradesList.filter((grade) => grade.gradeSection === section);
      updatedSelected.gradeIdx = 0;
      setGrades(filteredGrades);
    }
    if (type === 'grade') {
      updatedSelected.gradeIdx = +event.target.value;
    }
    setSelected(updatedSelected);
  };

  const openGradesMenu: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeGradeMenu = (gradeIdx?: number) => () => {
    setAnchorEl(null);
    if (!selected || gradeIdx === null || gradeIdx === undefined) return;
    setSelected({
      ...selected,
      gradeIdx,
    });
  };

  if (!grades.length || !selected) return null;

  const selectedGrade = grades[selected.gradeIdx];

  return (
    <Dialog
      isDialogOpen={!!props.isDialogOpen}
      title=""
      closeDialog={props.closeDialog}
      className="grades-dialog"
      content={
        <div className="grid-y grades-dialog-content">
          {sections.length > 0 && (
            <TextField
              data-id="section"
              value={selected.sectionIdx}
              onChange={onSelectChange('section')}
              variant="standard"
              SelectProps={{
                disableUnderline: true,
                style: { fontSize: 20, width: 'max-content' },
              }}
              label={I18n.gradeSection?.i18n_value || 'Grade Section'}
              InputLabelProps={{ shrink: true, className: 'text-faded' }}
              select
            >
              {sections.map((section, idx) => (
                <MenuItem key={section} value={idx} data-id={section} data-value={section}>
                  {section}
                </MenuItem>
              ))}
            </TextField>
          )}
          <div className="position-relative grade-block margin-top-1">
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeGradeMenu()}
            >
              {grades.map((grade, idx) => (
                <MenuItem
                  key={grade.grade}
                  selected={selected.gradeIdx === idx}
                  data-id={grade.grade}
                  onClick={closeGradeMenu(idx)}
                >
                  {grade.gradeLabel}
                </MenuItem>
              ))}
            </Menu>
            <PrimaryIconButton
              onClick={openGradesMenu}
              disabled={selected.gradeIdx === 0}
              className="position-absolute grade-previous"
              data-id="grade-previous"
            >
              <ArrowBackIos />
            </PrimaryIconButton>
            <TextField
              data-id="grade"
              value={selected.gradeIdx}
              onChange={onSelectChange('grade')}
              variant="standard"
              SelectProps={{
                disableUnderline: true,
                style: { fontSize: 20, width: 'max-content' },
              }}
              label={I18n.grade?.i18n_value || 'Grade'}
              InputLabelProps={{ shrink: true, className: 'text-faded' }}
              select
            >
              {grades.map((grade, idx) => (
                <MenuItem
                  key={grade.grade}
                  value={idx}
                  data-id={grade.grade}
                  data-value={grade.gradeLabel}
                >
                  {grade.gradeLabel}
                </MenuItem>
              ))}
            </TextField>
            <PrimaryIconButton
              onClick={openGradesMenu}
              disabled={selected.gradeIdx === grades.length - 1}
              className="position-absolute grade-next"
              data-id="grade-next"
            >
              <ArrowForwardIos />
            </PrimaryIconButton>
          </div>
          {selectedGrade.gradeDescription && (
            <div className="margin-top-1">
              <GradeDescription
                description={`<p>${selectedGrade.gradeDescription}</p>`}
                className="pxn-grade-description"
                data-id={`gradeDescription-${selectedGrade.grade}`}
              />
            </div>
          )}
          {/* show grade comparison only if description is available */}
          {selectedGrade.gradeComparison && selectedGrade.gradeDescription && (
            <>
              {selectedGrade.gradeComparison.description && (
                <div className="margin-top-1">
                  <GradeDescription
                    description={`<p>${selectedGrade.gradeComparison.description}</p>`}
                    className="pxn-grade-description"
                    data-id={`gradeComparison-${selectedGrade.grade}`}
                  />
                </div>
              )}
              {selectedGrade.gradeComparison.gradeMixChartEnabled &&
                selectedGrade.gradeComparison.gradeMixChart.length && (
                  <div data-id={`gradeMix-${selectedGrade.grade}`} className="margin-top-1">
                    <GradeMixChartPreview
                      gradeMixChart={selectedGrade.gradeComparison.gradeMixChart}
                    />
                  </div>
                )}
            </>
          )}
        </div>
      }
    />
  );
};

export default GradePopup;

const apiToUrlMap = {
  // user information
  getUserInfo: '/px-api-gateway/account/user-info',
  // system generated messages
  getCartSettings: '/px-api-gateway/stocklist/cart/settings',
  // completing payment
  getPaymentLink: '/px-api-gateway/order/{phonexOrderNumber}/payment',
  // confirm payment
  confirmPayment:
    '/px-api-gateway/order/{phonexOrderNumber}/payment/capture?token={token}&PayerID={payerID}',
  // poe export document
  getProofOfExportPendingDocuments: '/px-api-gateway/account/pending-documents',

  updateQuantityChannel: '/px-api-gateway/inventory/item/update-quantity-channel',
  inventoryItems: '/px-api-gateway/inventory/items',
  // stocks
  pxnSettings: '/px-api-gateway/pxn/settings',
  pxnStockSettings: '/px-api-gateway/pxn/stocklist-settings',
  pxnOrderSettings: '/px-api-gateway/pxn/salesorder-settings',
  pxnWarehouses: '/px-api-gateway/pxnr/stocklist/warehouses',
  pxnStockGrades: '/px-api-gateway/pxnr/stocklist/grades',
  pxnStockItems: '/px-api-gateway/pxnr/stocklist/items',
  pxnStockItemCounts: '/px-api-gateway/pxnr/stocklist/itemscount',
  stocksGet: '/px-api-gateway/stocklist/items',
  stockListExport: '/px-api-gateway/stocklist/export',
  stockListImport: '/px-api-gateway/stocklist/offer/bulk-offer-import',
  stockAlerts: '/px-api-gateway/stocklist/alert/items',
  stockDailyReport: '/px-api-gateway/account/user/notification-preferance',
  stockAlertQuantity: '/px-api-gateway/stocklist/alert/item/{itemNumberWarehouse}',
  // orders
  orderSettings: '/px-api-gateway/sales-order/settings',
  getOrdersV1: '/px-api-gateway/sales-orders',
  getOrderV1: '/px-api-gateway/sales-order/{phonexOrderNumber}',

  // new sales order
  pxUpsert: '/px-api-gateway/sales-order/px-upsert',
  calculateCharges: '/px-api-gateway/orderservices/calculate-charges',
  calculateFulfillmentCharges: '/px-api-gateway/orderservices/calculate-fulfillment-charges',
  inventoryAvailabilitySearch: '/px-api-gateway/stocklist/inventory/availability/search',

  // order export
  orderExportV1: '/px-api-gateway/sales-order/{phonexOrderNumber}/export-order',
  ordersExportV1: '/px-api-gateway/sales-orders/export-orders',
  orderInvoiceExportV1:
    '/px-api-gateway/sales-order/{phonexOrderNumber}/invoice/{invoiceNumber}/export',

  // manifest
  manifestSummary: '/px-api-gateway/sales-order/{phonexOrderNumber}/manifest/summary',
  manifestExport: '/px-api-gateway/sales-order/{phonexOrderNumber}/manifest/export',
  manifestExportAll: '/px-api-gateway/sales-order/manifest/export',

  inventorySettings: '/px-api-gateway/inventory/settings',

  offer: '/px-api-gateway/stocklist/offer',
  offers: '/px-api-gateway/stocklist/offers',
  pxnrStockOffer: '/px-api-gateway/pxnr/offer',
  addToExistingOrderWarehousesV1: '/px-api-gateway/sales-orders/onhold-order-warehouses',
  addToExistingOrder: '/px-api-gateway/stocklist/cart/{warehouseCode}/addToSalesOrder',
  pxcAddToExistingOrder: '/px-api-gateway/pxnr/cart/{warehouseCode}/addToSalesOrder',
  reviewCombinedOrder:
    '/px-api-gateway/stocklist/cart/{warehouseCode}/addToSalesOrder/{salesOrder}',
  pxcReviewCombinedOrder: '/px-api-gateway/pxnr/cart/{warehouseCode}/addToSalesOrder/{salesOrder}',
  updateCombinedOrder:
    '/px-api-gateway/stocklist/cart/{warehouseCode}/addToSalesOrder/{salesOrder}/submit',
  updateCombinedOrderV1:
    '/px-api-gateway/v2/stocklist/cart/{warehouseCode}/addToSalesOrder/{salesOrder}/submit',
  pxcUpdateCombinedOrder:
    '/px-api-gateway/pxnr/cart/{warehouseCode}/addToSalesOrder/{salesOrder}/submit',

  moveToCart: '/px-api-gateway/stocklist/offer/movetocart',
  pxnrStockMoveToCart: '/px-api-gateway/pxnr/offer/movetocart',
  cartSubmission: '/px-api-gateway/stocklist/cart/{warehouseCode}/submit',
  cartSubmissionV1: '/px-api-gateway/v2/stocklist/cart/{warehouseCode}/submit',
  cartCheckout: '/px-api-gateway/stocklist/cart/{warehouseCode}/checkout',
  pxcCartCheckout: '/px-api-gateway/pxnr/cart/{warehouseCode}/checkout',
  pxcCartSubmission: '/px-api-gateway/pxnr/cart/{warehouseCode}/submit',

  shippingBillingPreferencesOptions: '/px-api-gateway/orderservices/options',
  shippingBillingPreferencesOptionsv2: '/px-api-gateway/orderservices/options?newVersion=true',
  shippingBillingPreferencesSubmitv2:
    '/px-api-gateway/orderservices/options?pxnParticipantId={pxnParticipantId}',
  shippingBillingPreferencesDefaultOptions: '/px-api-gateway/orderservices/selectedoptions',
  shippingBillingPreferencesUpdateShipping: '/px-api-gateway/orderservices/options/shipping',
  shippingBillingPreferencesUpdatePayment: '/px-api-gateway/orderservices/options/payment',
  shippingBillingPreferencesUpdateServices: '/px-api-gateway/orderservices/options/services',
  shippingBillingPreferencesSelectedOptions: '/px-api-gateway/orderservices/selectedoptions',
  shippingBillingPreferencesSubmit: '/px-api-gateway/orderservices/options',
  orderServicesSettings: '/px-api-gateway/orderservices/settings',

  //Broadcast Banner
  broadcastBanner: '/px-api-gateway/notification/banners',

  //pxnNetwork
  pxnGradeConfig: '/px-api-gateway/pxnr/config/grades',
  //Reservation and Cancellation
  getReservationAndCancellationTerms: '/px-api-gateway/sales-order/{phonexOrderNumber}/rac-terms',
  acceptReservationAndCancellationTerms: '/px-api-gateway/sales-order/accept-rac-terms',
  calculateRACFees: '/px-api-gateway/sales-order/calculate-rac-fee',

  //Buyer onboarding
  getAccountSetup: '/buyer-onboarding/account-setup',
  getReferences: '/buyer-onboarding/references',
  accountSetupSuccess: '/buyer-onboarding/success-content',

  //account details
  getAccountDetails: '/px-api-gateway/account/details',
  // getAccountDetailsSectionInfo: '/px-api-gateway/account/info-sections',
  downloadUploadedDocument: '/px-api-gateway/account/documents/{uploadedDocumentId}',
  accountDocuments: '/px-api-gateway/account/documents',
  getOnlineFormDataIS:
    '/px-api-gateway/account/documents/{uploadedDocumentId}/formData?type=INTERNATIONAL_SHIPPING',
  getOnlineFormData: '/px-api-gateway/account/documents/{uploadedDocumentId}/formData',
  saveOnlineFormData: '/px-api-gateway/account/form',
  uploadMissingDocument: '/px-api-gateway/account/document/{documentReferenceId}',
  onboardingSettings: '/px-api-gateway/buyer-onboarding/settings',
  getPresignedURL: '/px-api-gateway/account/document/pre-signed-url',

  //auction bidding
  getAuctions: '/auction/listing',
  getAuctionDetails: '/auction/listing/{auctionNumber}',
  lotDetails: '/auction/listing/{auctionNumber}/lot/{lotNumber}',
  exportAuction: '/auction/listing/{auctionNumber}/export',
  exportAuctionLot: '/auction/listing/{auctionNumber}/lot/{lotNumber}/export',
  hasValidPreferences: '/px-api-gateway/orderservices/has-valid-preference',
  importAuctionBids: '/auction/listing/{auctionNumber}/import-bids',
  acceptAuctionBids: '/auction/listing/{auctionNumber}/import-bids/accept',

  //PO Awards
  getOpenAwards: '/po/awards/buyer?status={status}&buyerId={buyerId}',
  getClosedAwards:
    '/po/awards/buyer?status={status}&startDate={startDate}&endDate={endDate}&buyerId={buyerId}',
};

export type formatStringSignature = (str: string, arg: any) => string;
export function formatString(str: string, arg: any) {
  // eslint-disable-next-line no-useless-escape
  return str.replace(new RegExp('{[a-z]+}', 'gi'), function (match: string, index) {
    const key = match.slice(1, match.length - 1);
    return arg[key];
  });
}

export default apiToUrlMap;
